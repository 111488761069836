<template>
  <div class="join">
    <div>
      <img
        class="hidden-sm-and-down"
        style="width: 100%"
        src="../../assets/mock/platform2/编组 15@2x.png"
        alt=""
      />
      <img
        class="hidden-md-and-up"
        style="width: 100%"
        src="../../assets/mock/platform2/编组 3@2x.png"
        alt=""
      />
    </div>
    <div class="container warp">
      <ul class="bazaar">
        <li>
          <img
            width="100%"
            src="../../assets/mock/platform2/编组 6@2x.png"
            alt=""
          />
        </li>
        <li>
          <h3 class="bazaars">宇起数字产品数据防泄漏安全守护</h3>
          <p>
            宇起数字产品采用严格的数据加密保护技术，通过对传输中、存储中、使用中的数据进行加密保护，隐匿化处理，以降低敏感数据从终端的泄漏（网络、移动存储、通讯软件、应用系统）风险。
          </p>
        </li>
      </ul>
      <!-- league -->
      <div style="margin: 1.25rem 0; padding: 0.625rem 0">
        <div class="top">
          <h3>安全服务</h3>
          <span></span>
          <p>Security Services</p>
        </div>
        <div class="league">
          <ul>
            <li>
              <img src="../../assets/mock/platform2/编组 28@2x.png" alt="" />
              <h3>终端安全</h3>
              <p>
                排除单点故障，从网络、应用、数据库、存储等多个维度实现业务无单点故障，从而更好的保障生产活动。
              </p>
            </li>
            <li>
              <img src="../../assets/mock/platform2/编组 27@2x.png" alt="" />
              <h3>软件安全</h3>
              <p>APP数据手势密码进入保护，数据隐匿处理等细节层层防护。</p>
            </li>
            <li>
              <img src="../../assets/mock/platform2/编组 29@2x.png" alt="" />
              <h3>通信安全</h3>
              <p>设备通信传输全程加密防护。防篡改、防截取、防攻击。</p>
            </li>
            <li>
              <img src="../../assets/mock/platform2/编组 9@2x.png" alt="" />
              <h3>云数据安全</h3>
              <p>
                采用多层次、多维度的保护体系，措施包括数据保护、身份和访问管理、网络安全、应用程序安全、合规性和审计等。提供了全面而强大的安全保障，确保数据、应用程序和基础设施免受各种威胁和攻击。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <!-- 安全架构 -->
      <div class="top">
        <h3>数据安全架构</h3>
        <span></span>
        <p>Data Security Architecture</p>
        <div>
          <img
            style="width: 100%"
            src="../../assets/mock/platform2/编组 5@2x.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 保障 -->
    <div class="safeguard" style="background-color: #f7faff">
      <div class="top">
        <h3>服务器管理</h3>
        <span></span>
        <p>server management</p>
      </div>
      <div class="foot warp">
        <ul>
          <li>
            <img src="../../assets/mock/platform2/编组 21@2x.png" alt="" />
            <h5>数据库脱敏</h5>
            <p>
              对敏感信息通过脱敏规则进行数据的变形,实现敏感隐私数据的可靠保护
            </p>
          </li>
          <li>
            <img src="../../assets/mock/platform2/编组 20@2x.png" alt="" />
            <h5>职责分离</h5>
            <p>通过合理的组织分工以达到相互牵制、相互监督的作用</p>
          </li>
          <li>
            <img src="../../assets/mock/platform2/编组 19@2x.png" alt="" />
            <h5>代码加密</h5>
            <p>通过对源代码进行转换或者混淆,使得代码难以被理解和逆向工程</p>
          </li>
          <li>
            <img src="../../assets/mock/platform2/编组 18@2x.png" alt="" />
            <h5>KMS</h5>
            <p>通过密钥管理系统，进行严格的密钥管控</p>
          </li>
          <li>
            <img src="../../assets/mock/platform2/编组 16@2x.png" alt="" />
            <h5>最小权限管控</h5>
            <p>
              严格控制用户的访问权限，正确分配和明确文件和子目录等的访问权限
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
  margin-bottom: 1.6875rem;
  h3 {
    margin-top: 1.25rem;
  }
  span {
    width: 1.25rem;
    height: 3px;
    margin: 0.3125rem auto;
    display: block;
    background-color: #3cb59e;
  }
  p {
    font-size: 0.875rem;
  }
}
.container {
  .bazaar {
    display: flex;
    margin-top: 4.25rem;
    font-family: PingFangSC, PingFang SC;
    color: #333333;
    gap: 3.25rem;
    li {
      width: 100%;
      h3 {
        margin-bottom: 1.1875rem;
        font-size: 1.5rem;
      }

      p {
        font-size: 1.125rem;
        color: #333333;
      }
    }
  }
  @media only screen and (max-width: 920px) {
    .bazaar {
      flex-direction: column;
    }
    .bazaars{
      text-align: center;
      font-size: 1.5rem;
    }
  }
  .league {
    ul {
      display: flex;
      flex-flow: wrap;
      justify-content: space-evenly;
      overflow: hidden;
      li {
        text-align: center;
        width: 17.0625rem;
        border: 0.125rem solid #e5e5e5;
        overflow: hidden;
        margin-bottom: 0.625rem;
        img {
          width: 100%;
          // margin: 1.25rem 0;
          transition: all 0.2s;
        }
        img:hover {
          transform: scale(1.1);
        }
        h3 {
          margin: 0.5625rem 0;
          font-size: 1rem;
        }
        p {
          font-size: 0.9375rem;
          padding: 1.625rem;
          box-sizing: border-box;
        }
      }
    }
  }
}
.safeguard {
  overflow: hidden;
  padding: 3.9375rem 0;
  margin-bottom: 8.375rem;
  .foot {
    overflow-x: auto;
    ul {
      display: flex;
      // justify-content: center;
      justify-content: space-between;
      min-width: 43.75rem;
      margin-top: 0.825rem;
      gap: 4.5rem;
      li {
        text-align: center;
        flex: 1;
        h5 {
          margin: 0.625rem auto;
          font-size: 1.125rem;
        }
        img {
          width: 3.6458vw;
          min-width: 2.375rem;
        }
        p {
          font-size: 15px;
          color: #666;
          font-size: 0.9375rem;
        }
      }
    }
  }
}
</style>
